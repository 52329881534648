
import { computed, defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import BaseButton from '../components/Base/BaseButton.vue';
import { useLoader } from '../composables/useLoader';
import { useUser } from '../composables/useUser';
import { apiService } from '../services/ApiService';
import { MarketplaceService } from '../services/MarketplaceService';
import { shorthenedSellerAddress, weiToEther } from '../utils';
import VueNumberInput from '@chenfengyuan/vue-number-input';
import BaseToaster from '../components/Base/BaseToaster.vue';

export default defineComponent({
	components: { BaseButton, VueNumberInput, BaseToaster },
	setup() {
		const token = ref<any>({});
		const route = useRoute();
		const { openLoader, closeLoader } = useLoader();
		const { user, isUserLoggedIn, updateBalance } = useUser();

		const toaster = ref({
			visible: false,
			text: '',
			buttonText: '',
			type: '',
			action: () => (toaster.value.visible = false),
		});

		let marketplaceService: MarketplaceService;
		const isButtonDisabled = ref(true);

		(async function initMarketplace() {
			isButtonDisabled.value = true;
			marketplaceService = await MarketplaceService.getInstance();
			isButtonDisabled.value = false;
		})();

		const listPrice = ref('');

		const getTokenDetail = async () => {
			try {
				openLoader();
				const { contractAddress, tokenId } = route.params;
				const { data } = await apiService.getTokenDetail(
					contractAddress,
					tokenId,
				);
				token.value = data;
			} finally {
				closeLoader();
			}
		};

		getTokenDetail();

		const tags = computed(() => {
			if (token.value.tags) {
				token.value.tags
					.reduce((acc, tag) => {
						return `${acc} #${tag.value}`;
					}, '')
					.trim();
			}

			return '';
		});

		const isTokenMine = computed(() => {
			return user.value.address === token.value.owner;
		});

		const listToken = async () => {
			try {
				openLoader('LISTING NFT');
				isButtonDisabled.value = true;
				await marketplaceService.listToken(
					token.value.tokenId,
					`${token.value.contract.onChainId}`,
					`${listPrice.value}`,
				);
				toaster.value = {
					visible: true,
					text: 'Succesfully listed your NFT',
					buttonText: 'Close',
					type: 'success',
					action: () => (toaster.value.visible = false),
				};
				updateBalance();
			} catch {
				toaster.value = {
					visible: true,
					text: 'Some error occured while listing your NFT',
					buttonText: 'Close',
					type: 'danger',
					action: () => (toaster.value.visible = false),
				};
			} finally {
				isButtonDisabled.value = false;
				getTokenDetail();
			}
		};

		const buyToken = async () => {
			try {
				openLoader('BUYING NFT');
				isButtonDisabled.value = true;
				const listingIndex = token.value.listings.length - 1;
				await marketplaceService.buyToken(
					token.value.listings[listingIndex].listingId,
					token.value.price,
				);
				toaster.value = {
					visible: true,
					text: 'Succesfully bought NFT',
					buttonText: 'Close',
					type: 'success',
					action: () => (toaster.value.visible = false),
				};
				updateBalance();
			} catch {
				toaster.value = {
					visible: true,
					text: 'Some error occured while buying NFT',
					buttonText: 'Close',
					type: 'danger',
					action: () => (toaster.value.visible = false),
				};
			} finally {
				isButtonDisabled.value = false;
				getTokenDetail();
			}
		};

		const button = computed(() => {
			if (isUserLoggedIn.value) {
				if (isTokenMine.value) {
					if (!token.value.onSale) {
						return {
							visible: true,
							text: 'List',
							action: listToken,
						};
					}
					return {
						visible: false,
					};
				}
				if (token.value.onSale) {
					return {
						visible: true,
						text: 'Buy',
						action: buyToken,
					};
				}
			}
			return {
				visible: false,
			};
		});

		return {
			shorthenedSellerAddress,
			token,
			tags,
			button,
			isButtonDisabled,
			weiToEther,
			listPrice,
			toaster,
		};
	},
});
